<template>
  <VDialog v-model="visibility" max-width="400">
    <VCard>
      <VCardTitle class="headline" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-alert text dense type="error" class="w-100"  :class="{ 'text-caption': $vuetify.breakpoint.xs }"> {{$t('confirmDialog.accept_leave')}}</v-alert>
      </VCardTitle>
      <VCardText>
        <span class="text-left d-block"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
         {{$t('confirmDialog.changesInfo')}}
        </span>
        <span class="text-left d-block mt-3"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
         {{$t('confirmDialog.leaveInfo')}}
        </span>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn color="success" block dark text class="w-100 br-10" @click="visibility = false"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
              {{$t('confirmDialog.stay_btn')}}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn color="error" block class="w-100 br-10" dark @click="onConfirm"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
              {{$t('confirmDialog.leave_btn')}}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "ConfirmDeleteDialog",
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    onConfirm() {
      this.$emit("delete", this.uuid);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped></style>
